<template>
  <div class="add-contact">
    <div class="top-bar">
      <div class="back" @click="showComponent('AllContacts')">
        <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
        <p>Zurück</p>
      </div>
      <h5>Neuen Kontakt hinzufügen</h5>
    </div>

    <form @submit.prevent="searchUser">
      <!-- added name="search" for Safari, since placeholder="Username" triggers Safari's Autocomplete Password Manager -->
      <input class="input__text__rounded" type="text" name="search" placeholder="Username" title="Suche nach Username" v-model="username" />
      <button class="button__cta" :disabled="isLoading">
        <span class="loader" v-if="isLoading"></span>
        <span class="txt" v-else>Suchen</span>
      </button>
    </form>

    <!-- Search Results -->
    <div class="search-result" v-if="searchResult">
      <div class="contact" @click="addFriend">
        <div class="info">
          <img :src="require(`@/assets/icons/${searchResult.avatar}`)" alt="Avatar" />
          <p class="name">{{ searchResult.username }}</p>
        </div>
        <img class="add" v-if="!addingFriend" src="@/assets/icons/add.svg" alt="Add Contact" />
        <span class="loader" v-else></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddContact",
  data() {
    return {
      isLoading: false,
      username: "",
      searchResult: null,
      addingFriend: false,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_SOCIALHUB_COMPONENT", name);
    },
    searchUser() {
      if (this.username.length < 2) {
        this.flash("Bitte gib einen gültigen Username ein.", "warning", { timeout: 3500 });
      } else {
        this.isLoading = true;
        this.$store
          .dispatch("fetchUser", {
            username: this.username,
          })
          .then((response) => {
            this.isLoading = false;
            this.errMsg = "";
            this.searchResult = response.data;
          })
          .catch((error) => {
            if (error.response.status === 403) {
              let troll = {
                username: "I see what You did there!",
                avatar: "avatar_trollface.svg",
              };
              this.searchResult = troll;
              this.isLoading = false;
            }
            if (error.response.status === 404) {
              this.isLoading = false;
              this.flash(error.response.data.msg, "error", { timeout: 3500 });
              this.searchResult = null;
            }
            if (error.response.status === 500) {
              this.isLoading = false;
              this.flash("Hoppla, hier gab es ein Problem... Versuche es noch einmal.", "error", { timeout: 3500 });
              this.searchResult = null;
            }
          });
      }
    },
    addFriend() {
      if (!this.addingFriend && this.searchResult.id !== undefined) {
        this.addingFriend = true;
        this.$store
          .dispatch("addFriend", {
            user_id: this.searchResult.id,
          })
          .then(() => {
            this.addingFriend = false;
            this.searchResult = null;
            this.flash("Freundschaftsanfrage erfolgreich verschickt!", "success", { timeout: 3500 });
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.searchResult = null;
              this.isLoading = false;
              this.addingFriend = false;
              this.flash(error.response.data.msg, "warning", { timeout: 3500 });
            } else {
              this.addingFriend = false;
              this.searchResult = null;
              this.flash("Hoppla, hier gab es ein Problem... Versuche es noch einmal.", "error", { timeout: 3500 });
            }
          });
      } else {
        this.flash("Du kannst dich nicht selbst als Freund hinzufügen", "warning", { timeout: 3500 });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.add-contact {
  margin-top: 232px;
  width: 800px;
  height: calc(100vh - 400px);
  max-height: 1200px;
  background-color: $darker-navy-blue;
  display: flex;
  border-radius: 0 0 5px 5px;
  flex-direction: column;
  padding: 12px;
  @media screen and (max-width: 960px) {
    height: calc(100vh - 150px);
    margin-top: 148px;
    width: 100%;
    padding: 0 12px 80px 12px;
  }
  .top-bar {
    position: relative;
    margin-bottom: 12px;
    border-bottom: 1px solid $white;
    .back {
      top: 4px;
      left: 4px;
      position: absolute;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      img {
        height: 24px;
        margin-right: 16px;
      }
    }
    h5 {
      text-align: center;
      line-height: 2;
      @media screen and (max-width: 960px) {
        margin-top: 60px;
      }
    }
  }
  form {
    width: 480px;
    margin: 24px auto;
    @media screen and (max-width: 960px) {
      max-width: 480px;
      width: 100%;
    }
    .button__cta {
      width: 100%;
      margin-top: 12px;
    }
    .errMsg {
      margin-top: 16px;
    }
  }
  .search-result {
    width: 480px;
    margin: 24px auto;
    @media screen and (max-width: 960px) {
      max-width: 480px;
      width: 100%;
    }
    .contact {
      cursor: pointer;
      width: 100%;
      border: 1px solid $light-grey;
      display: flex;
      border-radius: 5px;
      padding: 6px 12px;
      justify-content: space-between;
      align-items: center;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: $light-grey;
      }
      .info {
        display: flex;
        align-items: center;
        img {
          height: 48px;
          width: 48px;
          margin-right: 20px;
        }
      }
      .add {
        width: 40px;
        height: 40px;
      }
      .loader {
        margin-right: 8px;
      }
    }
  }
}
</style>

<template>
  <div class="new-message">
    <div class="top-bar">
      <div @click="showComponent('AllChatRooms')" class="back">
        <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
        <p>Zurück</p>
      </div>
      <h5>Nachricht schreiben</h5>
      <form @submit.prevent>
        <input type="search" class="input__text__rounded" placeholder="Suchen ..." title="Suche nach Username" v-model="searchQuery" />
      </form>
    </div>

    <!-- Display whole Friendlist here -->
    <simplebar v-if="filteredFriends.length" data-simplebar-auto-hide="false" class="friendlist">
      <div
        class="friend"
        v-for="(item, index) in filteredFriends"
        :key="index"
        @click="
          showComponent('Chat');
          setChatRoomData(item);
        "
      >
        <div class="info">
          <img :src="require(`@/assets/icons/${item.avatar}`)" alt="Avatar" />
          <p class="name">{{ item.username }}</p>
        </div>
        <img class="add" src="@/assets/icons/add.svg" alt="Send Message" />
      </div>
    </simplebar>

    <!-- Display when friend list in empty -->
    <div class="noResults" v-else>
      <img src="@/assets/icons/Logo_NoResults.svg" alt="No Results" />
      <p>Es wurden keine Freunde gefunden, denen du schreiben kannst.</p>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "NewMessage",
  components: {
    simplebar,
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_SOCIALHUB_COMPONENT", name);
    },
    setChatRoomData(item) {
      let rooms = this.$store.getters.MY_CHAT_ROOMS;
      let chat_partner_ids = [];
      let data = [];
      for (let i = 0; i < rooms.length; i++) {
        chat_partner_ids.push(rooms[i].participants[0].id);
        data.push({
          user_id: rooms[i].participants[0].id,
          room_id: rooms[i].id,
        });
      }
      if (chat_partner_ids.includes(item.id)) {
        // Chat exists
        let roomId = data.find((x) => x.user_id === item.id).room_id;
        let participant_data = this.myFriends.find((x) => x.id === item.id);
        let user_data = []; // push necessary data to array
        user_data.push({
          id: participant_data.id,
          username: participant_data.username,
          avatar: participant_data.avatar,
        });
        let payload = {
          chatRoomId: roomId,
          participants: user_data,
        };
        this.$store.commit("SET_CHAT_ROOM_DATA", payload);
      } else {
        // Chat does not exist
        let payload = {
          user_id: item.id,
          username: item.username,
        };
        this.$store.commit("SET_CHAT_ROOM_DATA", payload);
      }
    },
  },
  computed: {
    // Filter Friendslist by what the User has typed in the Search Bar
    filteredFriends() {
      const allFriends = this.$store.state.myFriends;
      return allFriends.filter((item) => {
        return item.username.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.new-message {
  margin-top: 232px;
  width: 800px;
  height: calc(100vh - 400px);
  max-height: 1200px;
  background-color: $darker-navy-blue;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 5px 5px;
  padding: 12px;
  @media screen and (max-width: 960px) {
    height: calc(100vh - 150px);
    margin-top: 148px;
    width: 100%;
    padding: 0 0px 80px 0px;
  }
  .top-bar {
    border-bottom: 1px solid #979797;
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    position: sticky;
    top: 60px;
    z-index: 100;
    background-color: $darker-navy-blue;
    padding-top: 0px;
    @media screen and (max-width: 960px) {
      padding: 10px 24px;
      top: 92px;
    }
    .back {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      img {
        height: 24px;
        margin-right: 16px;
      }
    }
    h5 {
      @media screen and (max-width: 960px) {
        display: none;
      }
    }
    form {
      input {
        height: 40px;
        border-radius: 20px;
        width: 120px;
        &:focus {
          width: 232px;
        }
      }
    }
  }
  .friendlist {
    padding-top: 24px;
    height: 85%;
    .friend {
      cursor: pointer;
      width: calc(100% - 16px);
      border: 1px solid $light-grey;
      display: flex;
      padding: 12px 24px;
      justify-content: space-between;
      align-items: center;
      transition: all 0.2s ease-in-out;
      @media screen and (max-width: 960px) {
        border-left: none;
        border-right: none;
        width: 100%;
      }
      &:nth-of-type(even) {
        border-bottom: none;
        border-top: none;
      }
      &:last-of-type {
        border-bottom: 1px solid $light-grey;
      }
      &:hover {
        background-color: $light-grey;
      }
      .info {
        display: flex;
        align-items: center;
        img {
          height: 48px;
          width: 48px;
          margin-right: 20px;
        }
      }
      .add {
        width: 40px;
        height: 40px;
        @media screen and (max-width: 960px) {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
  .noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 24px;
    img {
      width: 80px;
      margin: 24px 0;
    }
  }
}
</style>

<template>
  <div class="chat-room">
    <div class="avatar" @click="showChat">
      <img :src="require(`@/assets/icons/${item.participants[0].avatar}`)" alt="Avatar" />
    </div>
    <div class="content" @click="showChat">
      <div class="top">
        <p class="name">{{ item.participants[0].username }}</p>
        <p class="date"><time-ago class="time" :refresh="60" :datetime="item.updated_at" long locale="de"></time-ago></p>
      </div>
      <div class="txt">{{ item.last_message.message }}</div>
    </div>
    <div class="btn-group" v-click-outside="hideInfobox">
      <InfoboxMessage v-show="showInfobox" v-bind:item="item" v-bind:index="index" />
      <img class="multi-select" src="@/assets/icons/multi-options.svg" @click="toggleInfobox" alt="Options" />
      <div class="btn-count" v-if="item.unread_messages.length">+{{ item.unread_messages.length }}</div>
    </div>
  </div>
</template>

<script>
import InfoboxMessage from "@/components/InfoboxMessage.vue";
import TimeAgo from "vue2-timeago";

export default {
  name: "ChatRoom",
  components: {
    InfoboxMessage,
    TimeAgo,
  },
  props: ["item", "index"],
  data() {
    return {
      showInfobox: false,
    };
  },
  methods: {
    toggleInfobox() {
      this.showInfobox = !this.showInfobox;
    },
    hideInfobox() {
      if (this.showInfobox === true) {
        this.showInfobox = false;
      }
    },
    showChat() {
      let payload = {
        chatRoomId: this.$props.item.id,
        participants: this.$props.item.participants,
      };
      this.$store.commit("SET_CHAT_ROOM_DATA", payload);
      this.$store.commit("SET_SOCIALHUB_COMPONENT", "Chat");

      // decrease new Messages Counter by amount of new Messages
      let newMessagesCount = this.item.unread_messages.length;
      this.$store.commit("DECREASE_NEW_MESSAGES_COUNT", newMessagesCount);

      // remove New Messages Badge
      this.item.unread_messages.length = 0;
    },
    showComponent(name) {
      this.$store.commit("SET_SOCIALHUB_COMPONENT", name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.chat-room {
  width: 100%;
  padding: 24px;
  border: 1px solid $light-grey;
  display: flex;
  height: 144px;
  &:hover {
    background-color: $darker-navy-blue;
    cursor: pointer;
  }
  &:nth-of-type(even) {
    border-bottom: none;
    border-top: none;
  }
  &:last-of-type {
    border-bottom: 1px solid $light-grey;
    margin-bottom: 16px;
  }
  @media screen and (max-width: 960px) {
    border-left: none;
    border-right: none;
    padding: 8px 16px;
    height: 112px;
  }
  .avatar {
    min-width: 64px;
    img {
      width: 64px;
      height: 64px;
      @media screen and (max-width: 960px) {
        width: 36px;
        height: 36px;
      }
    }
    @media screen and (max-width: 960px) {
      min-width: 36px;
      margin-top: 4px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin: 0 24px;
    flex-grow: 1;
    @media screen and (max-width: 960px) {
      margin-left: 12px;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .name {
        font-size: 20px;
      }
      .date {
        @media screen and (max-width: 960px) {
          font-size: 12px;
        }
      }
    }
    .txt {
      line-height: 1.5;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @media screen and (max-width: 960px) {
        font-size: 14px;
      }
    }
  }
  .btn-group {
    padding-top: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    .multi-select {
      align-self: flex-start;
      cursor: pointer;
      width: 40px;
      height: 40px;
      user-select: none;
      border-radius: 100%;
      &:active {
        filter: brightness(50%);
      }
    }
    .btn-count {
      width: 40px;
      height: 40px;
      user-select: none;
      margin-top: 8px;
      background: $gradient;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
    }
  }
}
</style>

<template>
  <div class="socialhub">
    <!-- <AppHeader/> -->
    <main>
      <div class="tab-navigation">
        <div
          class="chat"
          @click="showComponent('AllChatRooms')"
          :class="{ active: component === 'AllChatRooms' || component === 'Chat' || component === 'NewMessage' }"
        >
          Chats
          <div class="notification" v-if="newMessages > 0">+{{ newMessages }}</div>
        </div>
        <div class="contacts" @click="showComponent('AllContacts')" :class="{ active: component === 'AllContacts' || component === 'AddContact' }">
          Kontakte
          <div class="notification" v-if="friendRequests.length">+{{ friendRequests.length }}</div>
        </div>
      </div>

      <component :is="component" />
    </main>
  </div>
</template>

<script>
import AllChatRooms from "@/components/AllChatRooms.vue";
import AllContacts from "@/components/AllContacts.vue";
import Chat from "@/components/Chat.vue";
import AddContact from "@/components/AddContact.vue";
import NewMessage from "@/components/NewMessage.vue";

export default {
  name: "SocialHub",
  components: {
    AllChatRooms,
    AllContacts,
    Chat,
    AddContact,
    NewMessage,
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_SOCIALHUB_COMPONENT", name);
    },
  },
  computed: {
    component() {
      return this.$store.getters.SOCIALHUBCOMPONENT;
    },
    friendRequests() {
      return this.$store.getters.MY_FRIEND_REQUESTS;
    },
    newMessages() {
      return this.$store.getters.NEW_MESSAGES;
    },
  },
  mounted() {
    // get Friendlist
    this.$store.dispatch("getMyFriends");
    this.$store.dispatch("getFriendRequests");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.socialhub {
  main {
    min-height: calc(100vh - 315px);
    width: 800px;
    margin: auto;
    @media screen and (max-width: 960px) {
      width: 100%;
    }
    .tab-navigation {
      width: 800px;
      height: 100px;
      background-color: $navy-blue;
      z-index: 200;
      display: flex;
      align-items: flex-end;
      top: 132px;
      position: fixed;
      @media screen and (max-width: 960px) {
        top: 92px;
        height: 40px;
        padding: 0 24px;
        width: 100%;
      }
      .chat,
      .contacts {
        width: 50%;
        height: 40px;
        border: 1px solid $white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
      }
      .chat {
        border-radius: 5px 0 0 5px;
      }
      .contacts {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}

.active {
  background-color: $light-grey;
}

.notification {
  width: 34px;
  height: 24px;
  position: absolute;
  background: $gradient;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  right: 10px;
  @media screen and (max-width: 960px) {
    right: 4px;
  }
}
</style>

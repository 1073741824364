<template>
  <div class="all-chat-rooms">
    <div class="new-teamee-request__wrapper">
      <div to="/" class="new-message" @click="showComponent('NewMessage')">
        <p>Neue Nachricht</p>
        <img src="@/assets/icons/write-message_white.svg" alt="New Message" />
      </div>
    </div>

    <div class="messages">
      <div class="loader__wrapper" v-if="isLoading && !chatRooms.length">
        <span class="loader"></span>
      </div>
      <div class="chat-rooms__wrapper" v-if="chatRooms.length">
        <ChatRoom v-for="(item, index) in chatRooms" :key="index" v-bind:item="item" v-bind:index="index"></ChatRoom>
      </div>
      <p v-if="!isLoading && !chatRooms.length">Du hast noch keine Nachrichten.</p>
    </div>
  </div>
</template>

<script>
import ChatRoom from "@/components/ChatRoom.vue";

export default {
  name: "AllChatRooms",
  components: {
    ChatRoom,
  },
  data() {
    return {
      showMessages: true,
      isLoading: true,
      messages: [],
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_SOCIALHUB_COMPONENT", name);
    },
  },
  created() {
    this.$store.dispatch("getChatRooms").then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    chatRooms() {
      return this.$store.getters.MY_CHAT_ROOMS;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.all-chat-rooms {
  margin-top: 232px;
  @media screen and (max-width: 960px) {
    margin-top: 132px;
  }
  .new-teamee-request__wrapper {
    padding-top: 40px;
    padding-bottom: 16px;
    background-color: $navy-blue;
    position: sticky;
    display: flex;
    z-index: 200;
    top: 232px;
    @media screen and (max-width: 960px) {
      top: 132px;
      padding: 20px 24px 16px 24px;
    }
    .new-message {
      margin-left: auto;
      cursor: pointer;
      right: 0;
      display: flex;
      align-items: baseline;
      &:hover {
        text-decoration: underline;
      }
      img {
        height: 32px;
        margin-left: 16px;
        @media screen and (max-width: 960px) {
          height: 24px;
          margin-left: 8px;
        }
      }
    }
  }
  .messages {
    width: 100%;
    @media screen and (max-width: 960px) {
      padding-bottom: 68px;
    }
    .loader__wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
    p {
      text-align: center;
      margin-top: 40px;
    }
  }
}
</style>

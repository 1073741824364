<template>
  <div class="all-contacts">
    <div class="new-contact__wrapper">
      <simplebar data-simplebar-auto-hide="false" class="new-friend-request" v-if="friendRequests.length">
        <p>Neue Freundschaftsanfrage!</p>
        <transition-group name="list" tag="div">
          <div class="request" v-for="(item, index) in friendRequests" :key="index">
            <div class="info">
              <img :src="require(`@/assets/icons/${item.avatar}`)" alt="Avatar" />
              <p>{{ item.username }}</p>
            </div>
            <div class="btn-group">
              <img class="btn" src="@/assets/icons/accept.svg" alt="Accept" @click="acceptRequest(item, index)" />
              <img class="btn" src="@/assets/icons/decline.svg" alt="Decline" @click="declineRequest(item, index)" />
            </div>
          </div>
        </transition-group>
      </simplebar>

      <div to="/" class="new-contact" @click="showComponent('AddContact')">
        <p>Kontakt hinzufügen</p>
        <img src="@/assets/icons/add-person_white.svg" alt="Add Contact" />
      </div>
    </div>

    <!-- Contact List -->
    <div class="contacts" v-if="myFriends.length">
      <Contact v-for="(item, index) in myFriends" :key="index" v-bind:item="item" v-bind:index="index"></Contact>
    </div>

    <!-- Display when friend list in empty -->
    <div class="noResults" v-else>
      <img src="@/assets/icons/Logo_NoResults.svg" alt="No Results" />
      <p>Du hast noch keine Kontakte in deiner Freundesliste. Klicke auf "Kontakt hinzufügen", um jemanden deiner Liste hinzuzufügen.</p>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "AllContacts",
  components: {
    Contact,
    simplebar,
  },
  data() {
    return {
      editFriendRequest: false,
    };
  },
  methods: {
    acceptRequest(item, index) {
      this.editFriendRequest = true;
      this.$store.commit("REMOVE_FRIEND_REQUEST", index);
      this.$store
        .dispatch("acceptFriendRequest", {
          friend_request_id: item.id,
        })
        .then(() => {
          this.$store.dispatch("getMyFriends");
          this.editFriendRequest = false;
        });
    },
    declineRequest(item, index) {
      this.editFriendRequest = true;
      this.$store.commit("REMOVE_FRIEND_REQUEST", index);
      this.$store
        .dispatch("declineFriendRequest", {
          friend_request_id: item.id,
        })
        .then(() => {
          this.editFriendRequest = false;
        });
    },
    showComponent(name) {
      this.$store.commit("SET_SOCIALHUB_COMPONENT", name);
    },
  },
  computed: {
    myFriends() {
      return this.$store.state.myFriends;
    },
    friendRequests() {
      return this.$store.getters.MY_FRIEND_REQUESTS;
    },
  },
  mounted() {
    this.$store.dispatch("getMyFriends");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.list-enter-active,
.list-leave-active {
  transition: all 0.4s;
}

.list-leave-active {
  position: absolute;
  width: 100%;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}

.list-move {
  transition: transform 0.2s;
  transition-delay: 0.3s;
}

.all-contacts {
  margin-top: 232px;
  @media screen and (max-width: 960px) {
    margin-top: 132px;
  }
  .new-contact__wrapper {
    padding-top: 40px;
    padding-bottom: 16px;
    background-color: $navy-blue;
    position: sticky;
    display: flex;
    flex-direction: column;
    top: 232px;
    z-index: 200;
    @media screen and (max-width: 960px) {
      top: 132px;
      padding: 20px 24px 16px 24px;
    }
    .new-friend-request {
      width: 100%;
      margin-bottom: 16px;
      max-height: 180px;
      overflow: auto;
      @media screen and (max-width: 960px) {
        max-height: 120px;
      }
      .request {
        width: calc(100% - 16px);
        border: 1px solid $white;
        padding: 6px 12px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .info {
          display: flex;
          img {
            height: 40px;
            width: 40px;
            margin-right: 12px;
          }
        }
        .btn-group {
          display: flex;
          .btn {
            width: 36px;
            height: 36px;
            &:active {
              filter: brightness(50%);
            }
            &:hover {
              cursor: pointer;
            }
            &:first-of-type {
              margin-right: 8px;
            }
          }
        }
      }
    }
    .new-contact {
      margin-left: auto;
      right: 0;
      display: flex;
      align-items: baseline;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      img {
        height: 32px;
        margin-left: 16px;
        transform: translateY(6px);
        @media screen and (max-width: 960px) {
          height: 24px;
          margin-left: 8px;
          transform: translateY(5px);
        }
      }
    }
  }
  .contacts {
    width: 100%;
    @media screen and (max-width: 960px) {
      padding-bottom: 84px;
    }
    .loader__wrapper {
      width: 100%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .message {
      height: 60px;
      width: 100%;
      border: 1px solid $light-grey;
      &:nth-of-type(even) {
        border-bottom: none;
        border-top: none;
      }
      &:last-of-type {
        border-bottom: 1px solid $light-grey;
        margin-bottom: 16px;
      }
      @media screen and (max-width: 960px) {
        border-left: none;
        border-right: none;
      }
    }
  }
  .noResults {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    padding: 0 24px;
    img {
      width: 80px;
      margin: 24px 0;
    }
  }
}
</style>

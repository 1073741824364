<template>
  <div class="contact">
    <div class="info" @click="sendMessage">
      <img :src="require(`@/assets/icons/${item.avatar}`)" alt="Avatar" />
      <p class="name">{{ item.username }}</p>
    </div>
    <div class="multi-select-wrapper" v-click-outside="hideInfobox">
      <InfoboxContact v-show="showInfobox" v-bind:item="item" v-bind:index="index" />
      <img class="multi-select" src="@/assets/icons/multi-options.svg" @click="toggleInfobox" alt="Options" />
    </div>
  </div>
</template>

<script>
import InfoboxContact from "@/components/InfoboxContact.vue";

export default {
  name: "Contact",
  components: {
    InfoboxContact,
  },
  props: ["item", "index"],
  data() {
    return {
      showInfobox: false,
    };
  },
  methods: {
    toggleInfobox() {
      this.showInfobox = !this.showInfobox;
    },
    hideInfobox() {
      if (this.showInfobox === true) {
        this.showInfobox = false;
      }
    },
    showComponent(name) {
      this.$store.commit("SET_SOCIALHUB_COMPONENT", name);
    },
    sendMessage() {
      let payload = {
        user_id: this.$props.item.id,
        username: this.$props.item.username,
      };
      // Redirect User to Chat Window
      this.$store.commit("SET_CHAT_ROOM_DATA", payload);
      this.$store.commit("SET_SOCIALHUB_COMPONENT", "Chat");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.contact {
  width: 100%;
  border: 1px solid $light-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 960px) {
    border-left: none;
    border-right: none;
  }
  &:hover {
    background-color: $darker-navy-blue;
  }
  .info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 24px 0 24px 24px;
    img {
      height: 56px;
      width: 56px;
      margin-right: 20px;
    }
  }
  .multi-select-wrapper {
    position: relative;
    .multi-select {
      width: 40px;
      height: 40px;
      cursor: pointer;
      margin-right: 24px;
      &:active {
        filter: brightness(50%);
      }
    }
  }
}
</style>

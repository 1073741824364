<template>
  <div class="chat">
    <div class="top-bar">
      <div class="back" @click="showComponent('AllChatRooms')">
        <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
        <p>Zurück</p>
      </div>
      <h5>{{ chatRoomData.username || chatRoomData.participants[0].username }}</h5>
    </div>
    <div class="loader__wrapper" v-if="newDataLoading">
      <span class="loader"></span>
    </div>
    <simplebar data-simplebar-auto-hide="false" data-simplebar class="chat-body" ref="messagesContainer">
      <div class="message" v-for="(item, index) in messages.data" :key="index">
        <div class="avatar__wrapper">
          <img :src="require(`@/assets/icons/${item.user.avatar}`)" alt="Avatar" />
        </div>
        <div class="content__wrapper">
          <div class="info__wrapper">
            <div class="name">{{ item.user.username }}</div>
            <div class="timestamp"><time-ago class="time" :refresh="60" :datetime="item.created_at" tooltip="right" long locale="de"></time-ago></div>
          </div>
          <div class="message">{{ item.message }}</div>
        </div>
      </div>
    </simplebar>
    <div class="chat-input">
      <input
        class="input__text__rounded"
        type="text"
        placeholder="Schreibe eine Nachricht"
        title="Input Text"
        v-model="newMessage"
        @keyup.enter="sendMessage"
      />
      <button type="submit" @click="sendMessage">
        <img src="@/assets/icons/send-message.svg" alt="Send" />
      </button>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import TimeAgo from "vue2-timeago";
import api from "@/assets/api/api";

import Echo from "laravel-echo";

export default {
  name: "Chat",
  data() {
    return {
      messages: [],
      newMessage: "",
      newDataLoading: false,
    };
  },
  components: {
    simplebar,
    TimeAgo,
  },
  created() {
    this.connect();
  },
  mounted() {
    this.scroll();
    this.markMessagesAsRead();
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_SOCIALHUB_COMPONENT", name);
    },
    connect() {
      if (this.chatRoomData.chatRoomId) {
        this.getChatMessages();

        window.Pusher = require("pusher-js");
        window.Echo = new Echo({
          broadcaster: "pusher",
          key: "21e8c46664e0574ecddc",
          cluster: "eu",
          forceTLS: true,
          encrypted: true,
          authorizer: (channel) => {
            return {
              authorize: (socketId, callback) => {
                api
                  .post("/broadcasting/auth", {
                    socket_id: socketId,
                    channel_name: channel.name,
                  })
                  .then((response) => {
                    callback(false, response.data);
                  })
                  .catch((error) => {
                    callback(true, error);
                  });
              },
            };
          },
        });
        // listen to Private Channel
        window.Echo.private("user." + this.userId).listen("NewChatMessage", (e) => {
          // if a new Message is received, push data to Messages Array
          this.messages.data.push({
            created_at: e.chatMessage.created_at,
            user: { username: e.author.username, avatar: e.author.avatar },
            message: e.chatMessage.message,
          });
          this.scrollToEnd();
        });
      }
    },
    // fetch older Messages, when User scrolls all the way to the Top
    scroll() {
      const simplebar = this.$refs.messagesContainer.SimpleBar.getScrollElement();
      simplebar.addEventListener("scroll", () => {
        let oldHeight = simplebar.scrollHeight;
        if (simplebar.scrollTop == 0 && this.messages.next_page_url !== null && !this.newDataLoading) {
          this.newDataLoading = true;
          this.$store
            .dispatch("loadMoreChatMessages", {
              chat_room_id: this.chatRoomData.chatRoomId,
              url: this.messages.next_page_url,
            })
            .then((response) => {
              this.newDataLoading = false;
              let newMessages = response.data.data;
              this.messages.next_page_url = response.data.next_page_url; // set new url for next Request

              for (let index = 0; index < newMessages.length; index++) {
                const element = newMessages[index];
                this.messages.data.unshift(element); // push new Data to Messages Array
              }
              this.scrollTo(oldHeight);
            })
            .catch(() => {
              this.newDataLoading = false;
              this.flash("Es konnten leider keine Nachrichten geladen werden. Versuche es erneut.", "error", { timeout: 3500 });
            });
        }
      });
    },
    getChatMessages() {
      this.$store
        .dispatch("getChatMessages", this.$store.state.current_chat_room_data.chatRoomId)
        .then((response) => {
          response.data.data.reverse();
          this.messages = response.data;
          this.scrollToEnd();
        })
        .catch(() => {
          this.flash("Es konnten leider keine Nachrichten geladen werden. Versuche es erneut.", "error", { timeout: 3500 });
        });
    },
    sendMessage() {
      // if input Field is empty, simply return
      if (this.newMessage == "") {
        return;
      }

      // if Message in new Chat Room is sent -> execute following method
      if (this.chatRoomData["chatRoomId"] == undefined) {
        this.$store
          .dispatch("createNewChatRoom", {
            user_id: this.chatRoomData.user_id,
            message: this.newMessage,
          })
          .then(() => {
            this.getChatMessages();
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.flash(error.response.data.msg, "warning", { timeout: 5000 });
              this.messages.splice(-1, 1);
            } else {
              this.flash("Deine Nachricht konnte leider nicht verschickt werden. Versuche es erneut.", "error", { timeout: 3500 });
            }
          });

        this.newMessage = "";
        return;
      }

      // if Chat Room already exists, execute this method

      // push new Message immediately in order to have no Downtime
      this.messages.data.push({
        created_at: Date.now(),
        message: this.newMessage,
        user: {
          username: this.$store.state.user.username,
          avatar: this.$store.state.user.avatar,
        },
      });

      this.scrollToEnd(); // scroll down

      let receivers = [];
      for (let index = 0; index < this.chatRoomData.participants.length; index++) {
        let receiver_id = this.chatRoomData.participants[index].id;
        receivers.push(receiver_id);
      }

      this.$store
        .dispatch("sendMessage", {
          roomId: this.chatRoomData.chatRoomId,
          receivers: receivers,
          message: this.newMessage,
        })
        .then(() => {
          this.getChatMessages();
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.flash(error.response.data.msg, "error", { timeout: 5000 });
            this.messages.splice(-1, 1);
          } else {
            this.flash("Deine Nachricht konnte leider nicht verschickt werden. Versuche es erneut.", "error", { timeout: 3500 });
          }
        });

      this.newMessage = "";
    },

    // scroll to bottom of Chat Box when new message is beeing received
    scrollToEnd() {
      // set small Timeout until the new MessagesContainer is rendered
      setTimeout(() => {
        const content = this.$refs.messagesContainer.SimpleBar.getScrollElement();
        content.scrollTo(0, content.scrollHeight);
      }, 1);
    },
    scrollTo(oldHeight) {
      const simplebar = this.$refs.messagesContainer.SimpleBar.getScrollElement();
      setTimeout(() => {
        const newHeight = simplebar.scrollHeight;
        const scrollHeight = newHeight - oldHeight;
        simplebar.scrollTo(0, scrollHeight);
      }, 1);
    },
    markMessagesAsRead() {
      // mark Messages as read, only if Chat already exists and messages can be marked as such
      if (this.chatRoomData.participants) {
        let participant_ids = [];
        for (let index = 0; index < this.chatRoomData.participants.length; index++) {
          participant_ids.push(this.chatRoomData.participants[index].id);
        }

        this.$store.dispatch("markMessageAsRead", {
          chat_room_id: this.chatRoomData.chatRoomId,
          participants: participant_ids,
        });
      }
    },
  },
  computed: {
    chatRoomData() {
      return this.$store.getters.CURRENT_CHAT_ROOM_DATA; // contains username and id (if room exists -> also roomId)
    },
    userId() {
      return this.$store.getters.USER.id;
    },
  },
  destroyed() {
    this.markMessagesAsRead();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.chat {
  margin-top: 232px;
  width: 800px;
  height: calc(100vh - 400px);
  min-height: 180px;
  max-height: 1200px;
  background-color: $darker-navy-blue;
  display: flex;
  flex-direction: column;
  position: fixed;
  border-radius: 0 0 5px 5px;
  padding: 12px;
  @media screen and (max-width: 960px) {
    height: calc(100vh - 148px);
    margin-top: 148px;
    width: 100%;
    padding: 0 12px 0px 12px;
    padding-bottom: 148px;
  }
  @media screen and (max-height: 812px) and (max-width: 900px) {
    padding-bottom: 120px;
  }
  @media screen and (max-height: 568px) {
    padding-bottom: 80px;
  }

  .top-bar {
    position: relative;
    margin-bottom: 12px;
    border-bottom: 1px solid $white;
    .back {
      top: 4px;
      left: 4px;
      position: absolute;
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      img {
        height: 24px;
        margin-right: 16px;
      }
    }
    h5 {
      text-align: center;
      line-height: 2;
      @media screen and (max-width: 960px) {
        text-align: right;
        padding-right: 8px;
      }
    }
  }
  .loader__wrapper {
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translateX(-50%);
  }
  .chat-body {
    height: calc(100% - 124px);
    .message {
      display: flex;
      margin-bottom: 12px;
      .avatar__wrapper {
        margin-right: 12px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .content__wrapper {
        display: flex;
        flex-direction: column;
        .info__wrapper {
          display: flex;
          align-items: baseline;
          margin-bottom: 4px;
          .name {
            font-weight: 700;
            font-size: 16px;
            margin-right: 12px;
          }
          .timestamp {
            font-size: 12px;
            color: $grey;
          }
        }
      }
      .message {
        font-size: 15px;
        color: #cec6c6;
      }
    }
  }
  .chat-input {
    display: flex;
    padding-top: 12px;
    input {
      line-height: 1;
      flex-grow: 1;
      margin-right: 12px;
      height: 48px;
      background-color: $darker-navy-blue;
    }
    button {
      background-color: transparent;
      border: 1px solid $white;
      border-radius: 5px;
      min-width: 48px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $light-grey;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="infobox-contact">
    <div class="row" to="/" @click="sendMessage">
      <img class="icon" src="@/assets/icons/write-message_dark.svg" alt="Write Message" />
      <p>Nachricht senden</p>
    </div>

    <div class="row" to="/" @click="deleteFriend">
      <img class="icon" src="@/assets/icons/delete_dark.svg" alt="Delete" />
      <p>Kontakt löschen</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoboxContact",
  props: ["item", "index"],
  methods: {
    deleteFriend() {
      this.$store
        .dispatch("deleteFriend", {
          user_id: this.$props.item.id,
        })
        .then(() => {
          this.$store.commit("REMOVE_FRIEND", this.$props.index);
          this.flash("Kontakt wurde erfolgreich gelöscht.", "success", { timeout: 3500 });
        });
    },
    sendMessage() {
      let payload = {
        user_id: this.$props.item.id,
        username: this.$props.item.username,
      };

      // Redirect to Chat Window
      this.$store.commit("SET_CHAT_ROOM_DATA", payload);
      this.$store.commit("SET_SOCIALHUB_COMPONENT", "Chat");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.infobox-contact {
  user-select: none;
  cursor: default;
  position: absolute;
  padding: 12px 14px;
  background-color: #d8d8d8;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  right: 24px;
  top: 60px;
  z-index: 100;
  @media screen and (max-width: 960px) {
    right: 22px;
  }
  &:after {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid #d8d8d8;
    bottom: 100%;
    right: 10px;
    content: "";
    transform: rotate(45deg);
    margin-bottom: -12px;
    background-color: #d8d8d8;
    box-shadow: inset 0 0 4px 4px $orange;
    @media screen and (max-width: 960px) {
      right: 12px;
    }
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media screen and (max-width: 960px) {
      margin-bottom: 16px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:hover {
      p {
        text-decoration: underline;
      }
    }
    cursor: pointer;
    .icon {
      width: 20px;
      margin-left: 4px;
      margin-right: 14px;
    }
    p {
      color: $dark-navy-blue;
      white-space: nowrap;
    }
  }
}
</style>

<template>
  <div class="infobox-message">
    <div class="row" @click="deleteChatRoom">
      <img class="icon" src="@/assets/icons/delete_dark.svg" alt="Delete" />
      <p>Chatverlauf löschen</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoboxMessage",
  props: ["item", "index"],
  methods: {
    deleteChatRoom() {
      this.$store.commit("REMOVE_CHAT_ROOM", this.$props.index);
      this.$store
        .dispatch("deleteChatRoom", {
          roomId: this.$props.item.id,
        })
        .then(() => {
          this.flash("Der Chat wurde erfolgreich gelöscht.", "success", { timeout: 3500 });
        })
        .catch(() => {
          this.flash("Der Chat konnte nicht gelöscht werden. Versuche es erneut", "error", { timeout: 3500 });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.infobox-message {
  user-select: none;
  cursor: default;
  position: absolute;
  padding: 12px 14px;
  background-color: #d8d8d8;
  box-shadow: inset 0 0 1px 1px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  right: 0;
  top: 64px;
  z-index: 100;
  @media screen and (max-width: 960px) {
    right: -2px;
  }
  &:after {
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid #d8d8d8;
    bottom: 100%;
    right: 10px;
    content: "";
    transform: rotate(45deg);
    margin-bottom: -12px;
    background-color: #d8d8d8;
    box-shadow: inset 0 0 4px 4px $orange;
    @media screen and (max-width: 960px) {
      right: 12px;
    }
  }
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media screen and (max-width: 960px) {
      margin-bottom: 16px;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &:hover {
      p {
        text-decoration: underline;
      }
    }
    cursor: pointer;
    .icon {
      width: 20px;
      margin-left: 4px;
      margin-right: 14px;
    }
    p {
      color: $dark-navy-blue;
      white-space: nowrap;
    }
  }
}
</style>
